
<template>
<div>
   <div ref="div1" :style="{backgroundImage: 'url(' +imgUrl+ ')'}" @click="handleBodyClick"  class="_div">
  </div>
</div>
 
</template>

<script>
import androidImg from './../../assets/images/android.jpg'
import androidImg1 from './../../assets/images/android.jpg'
import iosImg from './../../assets/images/ios.jpg'
export default {
  data(){
    return {
      imgUrl:'',
      androidImg:androidImg,
      androidImg1:androidImg1,
      iosImg:iosImg,
      conheight:'200px'
    }
  },
  
  created(){
    window.addEventListener('resize', this.getHeight());
   
  },
 
  mounted(){
     this.$refs.div1.style.height = this.conheight
    if(this.isWechat()){
        //判断是否是微信浏览器
          if(this.os()==='android'){
             this.imgUrl=androidImg
          }else{
             this.imgUrl=iosImg
          }
      }else{
        if(this.os()==='android'){
          location.href =  'https://gxbapi.pluss.cn/apk/gxb.apk' 
          }
        else if(this.os()==='ios'){
          location.href =  'http://itunes.apple.com/us/app/id1469864386' 
          }
      }
  },

  methods:{
    getHeight(){
        this.conheight=window.innerHeight-40+'px';
        console.log(this.conheight)
    },

    handleBodyClick(){
      if(this.os()==='android'){
         this.imgUrl=androidImg1
        location.href =  'https://gxbapi.pluss.cn/apk/gxb.apk' 
      }else{
         this.imgUrl=iosImg
        location.href =  'http://itunes.apple.com/us/app/id1561351646' 
      } 
    },

    isWechat () {
      //是否微信端
      let ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
         return true    
        }
        else{
          return false
        }
    },

    os () {
        let temp = ''
        let u = navigator.userAgent, app = navigator.appVersion; 
        let  isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;//g 
		    let  isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);   //ios终端 
        if(isAndroid){
          temp = 'android'
        }else if(isIOS){
          temp = 'ios'
        }
        return temp
    }
}
}
</script>

<style scoped>
    ._div{
      font-size: 12px;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      overflow: hidden;
    }
</style>


